.title{
    font-family: "Rubik";
    color: white;
    text-shadow: 12px 2px 4px rgba(0, 0, 0, 0.414);
    font-size: 4rem;
    text-align: center;
    direction: rtl;
    
    }
    .description{
        font-family: "Rubik";
        color: white;
        text-shadow: 7px 2px 4px rgba(0, 0, 0, 0.414);
        font-size: 2rem;
        text-align: center;
        line-height: 1.5;
        margin:2% auto;
        direction: rtl;
        }

        .bigWrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width:100%;
        }
        .step{
            width:70%;
            margin: 2% auto;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        
        }
        .icons{
            height:250px;
            height:250px;
            flex:1
        }
        .text{
            display: flex;
            flex-direction: column;
            flex:1;
         margin: 3%;
         justify-content: space-evenly;
        
        }
        .stepNum{
            font-family: "Rubik";
            font-size: 40px;
            text-align: center;
            color:rgb(255, 255, 255);
            width:100%;
            
        }
        .stepHeader{
            font-family: "RubikR";
            font-size: 45px;
            text-align: center;
            color:rgb(255, 255, 255);
            margin:2% auto;
            
        }
        
        
        .section{
            font-family: "RubikR";
            font-size: 22px;
            text-align: center;
            color:rgb(255, 255, 255);
            direction: rtl;
            
            
            width:80%;
            margin:0 auto;
        }
        @media only screen and (max-width: 350px) {
        
            .title{
                font-family: "Rubik";
                color: white;
                text-shadow: 12px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 2rem;
                text-align: center;
                width:90%;
                margin:2% auto;
                direction: rtl;
                
                }
   
            .bigWrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width:100%;
            }
            .step{
                width:95%;
                margin: 7% auto;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
            }
            .icons{
                height:120px;
                height:120px;
                flex:1
            }
            .text{
                display: flex;
                flex-direction: column;
                flex:1.1;
             margin:3%
            
            }
        
            .stepHeader{
                font-family: "RubikR";
                font-size: 30px;
                text-align: center;
                color:rgb(255, 255, 255);
            width:100%;
            
              
            }
            
            
            .section{
                font-family: "RubikR";
                font-size: 14px;
                text-align: center;
                color:rgb(255, 255, 255);
                direction: rtl;
                
                width:100%;
                margin:0 auto;
            }
        }
        @media only screen and (min-width: 350px) and (max-width: 450px) {
        
            .title{
                font-family: "Rubik";
                color: white;
                text-shadow: 5px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 2.5rem;
                text-align: center;
                width:90%;
                margin:2% auto;
                direction: rtl;
                
                }
            .bigWrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width:100%;
            }
            .step{
                width:95%;
                margin: 5% auto;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
            }
            .icons{
                height:140px;
                height:140px;
                flex:1
            }
            .text{
                display: flex;
                flex-direction: column;
                flex:1.1;
             margin:3%
            
            }
        
            .stepHeader{
                font-family: "RubikR";
                font-size: 30px;
                text-align: center;
                color:rgb(255, 255, 255);
            width:100%;
              
            }
            
            
            .section{
                font-family: "RubikR";
                font-size: 14px;
                text-align: center;
                color:rgb(255, 255, 255);
                direction: rtl;
                
                width:100%;
                margin:0 auto;
            }
        }
        @media only screen and (min-width: 450px) and (max-width: 550px) {
            .title{
                font-family: "Rubik";
                color: white;
                text-shadow: 5px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 2.8rem;
                text-align: center;
                width:80%;
                margin:2% auto;
                direction: rtl;
                
                }
 
            .bigWrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width:100%;
            }
            .step{
                width:95%;
                margin: 5% auto;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
            }
            .icons{
                height:140px;
                height:140px;
                flex:1
            }
            .text{
                display: flex;
                flex-direction: column;
                flex:1.1;
             margin:3%
            
            }
        
            .stepHeader{
                font-family: "RubikR";
                font-size: 35px;
                text-align: center;
                color:rgb(255, 255, 255);
            width:100%;
              
            }
            
            
            .section{
                font-family: "RubikR";
                font-size: 16px;
                text-align: center;
                color:rgb(255, 255, 255);
                direction: rtl;
                
                width:100%;
                margin:0 auto;
            }
        }
        @media only screen and (min-width: 550px) and (max-width: 650px) {
            .title{
                font-family: "Rubik";
                color: white;
                text-shadow: 5px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 2.8rem;
                text-align: center;
                width:70%;
                margin:2% auto;
                direction: rtl;
                
                }
                
        

            .bigWrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width:100%;
            }
            .step{
                width:95%;
                margin: 5% auto;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
            }
            .icons{
                height:160px;
                height:160px;
                flex:1
            }
            .text{
                display: flex;
                flex-direction: column;
                flex:1.1;
             margin:3%
            
            }
        
            .stepHeader{
                font-family: "RubikR";
                font-size: 40px;
                text-align: center;
                color:rgb(255, 255, 255);
            width:100%;
              
            }
            
            
            .section{
                font-family: "RubikR";
                font-size: 18px;
                text-align: center;
                color:rgb(255, 255, 255);
                direction: rtl;
                
                width:100%;
                margin:0 auto;
            }
        }
        @media only screen and (min-width: 650px) and (max-width: 850px) {
        

            .bigWrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width:100%;
            }
            .title{
                font-family: "Rubik";
                color: white;
                text-shadow: 5px 2px 4px rgba(0, 0, 0, 0.414);
                font-size:3rem;
                text-align: center;
                width:90%;
                margin:2% auto;
                direction: rtl;
                
                }
            .step{
                width:85%;
                margin: 5% auto;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
            }
            .icons{
                height:160px;
                height:160px;
                flex:1
            }
            .text{
                display: flex;
                flex-direction: column;
                flex:1.1;
             margin:3%
            
            }
        
            .stepHeader{
                font-family: "RubikR";
                font-size: 40px;
                text-align: center;
                color:rgb(255, 255, 255);
            width:100%;
              
            }
            
            
            .section{
                font-family: "RubikR";
                font-size: 18px;
                text-align: center;
                color:rgb(255, 255, 255);
                direction: rtl;
                
                width:100%;
                margin:0 auto;
            }
        }
        @media only screen and (min-width: 850px) and (max-width: 1050px) {
        
            .title{
                font-family: "Rubik";
                color: white;
                text-shadow: 5px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 3.2rem;
                text-align: center;
                width:90%;
                margin:2% auto;
                direction: rtl;
                
                }
            .bigWrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width:100%;
            }
            .step{
                width:85%;
                margin: 5% auto;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
            }
            .icons{
                height:160px;
                height:160px;
                flex:1
            }
            .text{
                display: flex;
                flex-direction: column;
                flex:1.1;
             margin:3%
            
            }
        
            .stepHeader{
                font-family: "RubikR";
                font-size: 40px;
                text-align: center;
                color:rgb(255, 255, 255);
            width:100%;
         
            }
            
            
            .section{
                font-family: "RubikR";
                font-size: 18px;
                text-align: center;
                color:rgb(255, 255, 255);
                direction: rtl;
                
                width:100%;
                margin:1% auto;
            }
        }