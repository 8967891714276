.wrapper{
    display: flex;
    flex-direction: row;
    width:70%;
    margin:3% auto;
    height:auto;
}
.header{
    font-size: 5rem;
    font-family: "Noto";
    color: white;
    text-shadow: 7px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: center;
    margin:3% auto;
}
.right{
    display: flex;
    flex-direction: column;
    flex: 4; /* Adjusted flex property to make it twice the size of the image */
    justify-content: flex-end; /* Aligned content to the right */
    margin-left: auto;
    line-height: 1.6;
}

.title, .job{
    font-family: "Noto";
    color: white;
    text-shadow: 7px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: right;
}
.title{
    font-size: 4rem;
  
}
.job{
    font-size: 2.5rem;
 
}
.description{
    font-size: 1.5rem;
    color: white;
    text-shadow: 7px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: right;
    font-family: "NotoR";
}
.left{
    display: flex;
    flex: 2; /* Adjusted flex property */
}

.imageWrapper {
    width: 50%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image{
    width: 100%; /* Adjusted width to fill the container */
    object-fit: cover;
    height: 100%;
}

@media only screen  and (max-width: 350px){


    .wrapper{
        display: flex;
        flex-direction: row;
        width:95%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 3rem;
        font-family: "Noto";
        color: white;
        text-shadow:5px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: flex-end; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.6;
    }
    
    .title, .job{
        font-family: "Noto";
        color: white;
        text-shadow: 3px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 1.5rem;
      
    }
    .job{
        font-size: 1rem;
     
    }
    .description{
        font-size: 1rem;
        color: white;
        text-shadow: 3px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        direction: rtl;
        font-family: "NotoR";
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
    }
    
    .imageWrapper {
        width: 90%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }

}
@media only screen   and (min-width: 350px) and (max-width: 450px){


    .wrapper{
        display: flex;
        flex-direction: row;
        width:95%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 3.2rem;
        font-family: "Noto";
        color: white;
        text-shadow: 7px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: flex-end; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.6;
    }
    
    .title, .job{
        font-family: "Noto";
        color: white;
        text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 1.7rem;
      
    }
    .job{
        font-size: 1.2rem;
     
    }
    .description{
        font-size: 1.1rem;
        color: white;
        text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        direction: rtl;
        font-family: "NotoR";
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
    }
    
    .imageWrapper {
        width: 90%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }

}
@media only screen   and (min-width: 450px) and (max-width: 550px){


    .wrapper{
        display: flex;
        flex-direction: row;
        width:95%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 3.2rem;
        font-family: "Noto";
        color: white;
        text-shadow: 7px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: flex-end; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.6;
    }
    
    .title, .job{
        font-family: "Noto";
        color: white;
        text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 1.7rem;
      
    }
    .job{
        font-size: 1.2rem;
     
    }
    .description{
        font-size: 1.1rem;
        color: white;
        text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        direction: rtl;
        font-family: "NotoR";
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
    }
    
    .imageWrapper {
        width: 90%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }

}
@media only screen   and (min-width: 550px) and (max-width: 650px){


    .wrapper{
        display: flex;
        flex-direction: row;
        width:95%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 3.5rem;
        font-family: "Noto";
        color: white;
        text-shadow: 7px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: flex-end; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.6;
    }
    
    .title, .job{
        font-family: "Noto";
        color: white;
        text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 1.8rem;
      
    }
    .job{
        font-size: 1.4rem;
     
    }
    .description{
        font-size: 1.2rem;
        color: white;
        text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        direction: rtl;
        font-family: "NotoR";
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
    }
    
    .imageWrapper {
        width: 90%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }

}
@media only screen   and (min-width: 650px) and (max-width: 850px){


    .wrapper{
        display: flex;
        flex-direction: row;
        width:95%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 3.5rem;
        font-family: "Noto";
        color: white;
        text-shadow: 7px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: flex-end; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.6;
    }
    
    .title, .job{
        font-family: "Noto";
        color: white;
        text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 2rem;
      
    }
    .job{
        font-size: 1.5rem;
     
    }
    .description{
        font-size: 1.2rem;
        color: white;
        text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        margin-right: auto;
      width:85%;
        direction: rtl;
        font-family: "NotoR";
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
    }
    
    .imageWrapper {
        width: 90%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }

}
@media only screen   and (min-width: 850px) and (max-width: 1050px){


    .wrapper{
        display: flex;
        flex-direction: row;
        width:95%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 3.8rem;
        font-family: "Noto";
        color: white;
        text-shadow: 7px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: flex-end; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.6;
    }
    
    .title, .job{
        font-family: "Noto";
        color: white;
        text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 2.3rem;
      
    }
    .job{
        font-size: 1.7rem;
     
    }
    .description{
        font-size: 1.3rem;
        color: white;
        text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
     width:75%;
      margin-right: auto;
        direction: rtl;
        font-family: "NotoR";
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
    }
    
    .imageWrapper {
        width: 90%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }

}
@media only screen   and (min-width: 1050px) and (max-width: 1250px){


    .wrapper{
        display: flex;
        flex-direction: row;
        width:95%;
        margin:3% auto;
        height:auto;
    }
    .header{
        font-size: 4rem;
        font-family: "Noto";
        color: white;
        text-shadow: 7px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
    }
    .right{
        display: flex;
        flex-direction: column;
        flex: 4; /* Adjusted flex property to make it twice the size of the image */
        justify-content: flex-end; /* Aligned content to the right */
        margin-left: auto;
        line-height: 1.6;
    }
    
    .title, .job{
        font-family: "Noto";
        color: white;
        text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 2.5rem;
      
    }
    .job{
        font-size: 1.8rem;
     
    }
    .description{
        font-size: 1.4rem;
        color: white;
        text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
     width:75%;
      margin-right: auto;
        direction: rtl;
        font-family: "NotoR";
    }
    .left{
        display: flex;
        flex: 2; /* Adjusted flex property */
    }
    
    .imageWrapper {
        width: 90%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        width: 100%; /* Adjusted width to fill the container */
        object-fit: cover;
        height: 100%;
    }

}