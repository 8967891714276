.button{

    height:3rem;
  width:15rem;
   
    background: radial-gradient(circle, rgba(255,201,159,0.7030461842940301) 0%, rgba(255,244,0,0.7086484251903886) 100%);
    box-shadow: 0 0 8px 2px rgb(255, 195, 187);
    border-radius: 10px;
    margin:2% auto;
    cursor: pointer;
    font-family: "RubikR";
    font-size: 16px;
    border: none;
    direction: rtl;
    transition: all ease-out 1s;
    color:black;
    text-decoration: none;
}
.button:hover{
transform: scale(1.1);

}
.center{
    display: flex;
    width: 100%;
    margin:3% auto;
    justify-content: center;
}