.title{
font-family: "Rubik";
color: white;
text-shadow: 12px 2px 4px rgba(0, 0, 0, 0.414);
font-size: 4rem;
text-align: center;
direction: rtl;

}
.description{
    font-family: "Rubik";
    color: white;
    text-shadow: 7px 2px 4px rgba(0, 0, 0, 0.414);
    font-size: 2rem;
    text-align: center;
    line-height: 1.5;
    margin:2% auto;
    direction: rtl;
    }
    .descriptionFixed{
        font-family: "Rubik";
        color: white;
        text-shadow: 7px 2px 4px rgba(0, 0, 0, 0.414);
        font-size: 2rem;
        text-align: center;
        line-height: 1.5;
        margin:2% auto;
        direction: rtl;
        padding-top:80px;
    }
    .bold{
        display: inline;
        color: rgb(255, 247, 0);
        font-size: 4.5rem;
    }
    .boldS{
        display: inline;
        color: rgb(255, 247, 0);
    
    }
    .icon{
        display: flex;
        width: 20%;
        margin: 2% auto;
     z-index: -1;
     position: relative;
    }
    @media only screen  and (max-width: 350px){

        .title{
            font-family: "Rubik";
            color: white;
            text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
            font-size:1.7rem;
            text-align: center;
            direction: rtl;
            width:95%; 
            margin:0 auto;
            
            }
            .bold{
                display: inline;
                color: rgb(255, 247, 0);
                font-size: 1.9rem;
            }
            .description{
                font-family: "Rubik";
                color: white;
                text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 1.1rem;
                text-align: center;
                line-height: 1.8;
                margin:4% auto;
                direction: rtl;
                
                }
                .descriptionFixed{
                    font-family: "Rubik";
                    color: white;
                    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                    font-size: 1.1rem;
                    text-align: center;
                    line-height: 1.8;
                    margin:4% auto;
                    direction: rtl;
                    padding-top: 80px;
                }
                .icon{
                    display: flex;
                    width: 60%;
                    margin: 2% auto;
                 z-index: -1;
                 position: relative;
                }
    }
    @media only screen   and (min-width: 350px) and (max-width: 450px){

        .title{
            font-family: "Rubik";
            color: white;
            text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
            font-size:1.9rem;
            text-align: center;
            direction: rtl;
            width:95%; 
            margin:0 auto;
            
            }
            .bold{
                display: inline;
                color: rgb(255, 247, 0);
                font-size: 2.1rem;
            }
            .description{
                font-family: "Rubik";
                color: white;
                text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 1.2rem;
                text-align: center;
                line-height: 1.8;
                margin:4% auto;
                direction: rtl;
                width:90%;
                
                }
                .descriptionFixed{
                    font-family: "Rubik";
                    color: white;
                    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                    font-size: 1.2rem;
                    text-align: center;
                    line-height: 1.8;
                    margin:4% auto;
                    direction: rtl;
                    width:90%;
                    padding-top: 80px;
                }
                .icon{
                    display: flex;
                    width: 55%;
                    margin: 2% auto;
                 z-index: -1;
                 position: relative;
                }
    }
    @media only screen   and (min-width: 450px) and (max-width: 550px){

        .title{
            font-family: "Rubik";
            color: white;
            text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
            font-size:2rem;
            text-align: center;
            direction: rtl;
            width:95%; 
            margin:0 auto;
            
            }
            .bold{
                display: inline;
                color: rgb(255, 247, 0);
                font-size: 2.2rem;
            }
            .description{
                font-family: "Rubik";
                color: white;
                text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 1.3rem;
                text-align: center;
                line-height: 1.8;
                margin:4% auto;
                direction: rtl;
                width:90%;
                
                }
                .descriptionFixed{
                    font-family: "Rubik";
                    color: white;
                    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                    font-size: 1.3rem;
                    text-align: center;
                    line-height: 1.8;
                    margin:4% auto;
                    direction: rtl;
                    width:90%;
                    padding-top: 80px;
                    }
                .icon{
                    display: flex;
                    width: 45%;
                    margin: 2% auto;
                 z-index: -1;
                 position: relative;
                }
    }
    @media only screen   and (min-width: 550px) and (max-width: 650px){

        .title{
            font-family: "Rubik";
            color: white;
            text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
            font-size:2.2rem;
            text-align: center;
            direction: rtl;
            width:95%; 
            margin:0 auto;
            
            }
            .bold{
                display: inline;
                color: rgb(255, 247, 0);
                font-size: 2.4rem;
            }
            .description{
                font-family: "Rubik";
                color: white;
                text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 1.4rem;
                text-align: center;
                line-height: 1.8;
                margin:4% auto;
                direction: rtl;
                width:90%;
                
                }
                .descriptionFixed{
                    font-family: "Rubik";
                    color: white;
                    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                    font-size: 1.4rem;
                    text-align: center;
                    line-height: 1.8;
                    margin:4% auto;
                    direction: rtl;
                    width:90%;
                    padding-top: 80px;
                    }
                .icon{
                    display: flex;
                    width: 40%;
                    margin: 2% auto;
                 z-index: -1;
                 position: relative;
                }
    }
    @media only screen   and (min-width: 650px) and (max-width: 750px){

        .title{
            font-family: "Rubik";
            color: white;
            text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
            font-size:2.8rem;
            text-align: center;
            direction: rtl;
            width:95%; 
            margin:0 auto;
            
            }
            .bold{
                display: inline;
                color: rgb(255, 247, 0);
                font-size: 3rem;
            }
            .description{
                font-family: "Rubik";
                color: white;
                text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 1.5rem;
                text-align: center;
                line-height: 1.8;
                margin:4% auto;
                direction: rtl;
                width:70%;
                }
                .descriptionFixed{
                    font-family: "Rubik";
                    color: white;
                    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                    font-size: 1.5rem;
                    text-align: center;
                    line-height: 1.8;
                    margin:4% auto;
                    direction: rtl;
                    width:70%;
                    padding-top:80px;
                    }
                .icon{
                    display: flex;
                    width: 35%;
                    margin: 2% auto;
                 z-index: -1;
                 position: relative;
                }
    }
    @media only screen   and (min-width: 750px) and (max-width: 850px){

        .title{
            font-family: "Rubik";
            color: white;
            text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
            font-size:3rem;
            text-align: center;
            direction: rtl;
            width:95%; 
            margin:0 auto;
            
            }
            .bold{
                display: inline;
                color: rgb(255, 247, 0);
                font-size: 3.2rem;
            }
            .description{
                font-family: "Rubik";
                color: white;
                text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 1.6rem;
                text-align: center;
                line-height: 1.8;
                margin:4% auto;
                direction: rtl;
                width:70%;
                
                }
                .descriptionFixed{
                    font-family: "Rubik";
                    color: white;
                    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                    font-size: 1.6rem;
                    text-align: center;
                    line-height: 1.8;
                    margin:4% auto;
                    direction: rtl;
                    width:70%;
                    padding-top: 80px;
                    }
                .icon{
                    display: flex;
                    width: 30%;
                    margin: 2% auto;
                 z-index: -1;
                 position: relative;
                }
    }
    @media only screen   and (min-width: 850px) and (max-width: 1050px){

        .title{
            font-family: "Rubik";
            color: white;
            text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
            font-size:3.2rem;
            text-align: center;
            direction: rtl;
            width:95%; 
            margin:0 auto;
            
            }
            .bold{
                display: inline;
                color: rgb(255, 247, 0);
                font-size: 3.5rem;
            }
            .description{
                font-family: "Rubik";
                color: white;
                text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 1.6rem;
                text-align: center;
                line-height: 1.8;
                margin:3% auto;
                direction: rtl;
                width:60%;
                
                }
                .descriptionFixed{
                    font-family: "Rubik";
                    color: white;
                    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                    font-size: 1.6rem;
                    text-align: center;
                    line-height: 1.8;
                    margin:3% auto;
                    direction: rtl;
                    width:60%;
                    padding-top: 80px;
                    }
                .icon{
                    display: flex;
                    width: 25%;
                    margin: 2% auto;
                 z-index: -1;
                 position: relative;
                }
    }
    @media only screen   and (min-width: 1050px) and (max-width: 1250px){

        .title{
            font-family: "Rubik";
            color: white;
            text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
            font-size:3.5rem;
            text-align: center;
            direction: rtl;
            width:95%; 
            margin:0 auto;
            
            }
            .bold{
                display: inline;
                color: rgb(255, 247, 0);
                font-size: 3.8rem;
            }
            .description{
                font-family: "Rubik";
                color: white;
                text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                font-size: 1.7rem;
                text-align: center;
                line-height: 1.8;
                margin:2% auto;
                direction: rtl;
                width:50%;
                
                }
                .descriptionFixed{
                    font-family: "Rubik";
                    color: white;
                    text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.414);
                    font-size: 1.7rem;
                    text-align: center;
                    line-height: 1.8;
                    margin:2% auto;
                    direction: rtl;
                    width:50%;
                    padding-top: 80px;
                    }
                .icon{
                    display: flex;
                    width: 20%;
                    margin: 2% auto;
                 z-index: -1;
                 position: relative;
                }
    }