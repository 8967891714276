

.navbar {
 
    display: flex;
    width:100%;
    background:rgba(255, 255, 255, 0.256);

    height:80px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    align-items: center;
    justify-content: space-between;
    padding: 20px;
 box-shadow: 0 0 5px 2px rgba(9, 9, 9, 0.345);
    box-sizing: border-box;
 transition: all ease-out 1s;
  }
  .floatNav{
    display: flex;
    width:100%;
 background:none;
  position: fixed;
  top: -80px;
  z-index: 999;
  left:0;
  transform: translateY(80px);
  height:80px;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(10px);
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  box-shadow: 0 0 5px 2px rgba(9, 9, 9, 0.345);
    box-sizing: border-box;
  transition: all ease-out 1s;
  }
  
  .logoContainer {
    flex: 1;
    display: flex;

  }
  .logo a {
    color: #ffffff;
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
  }
  
  .navLinks {
    position: fixed;
    top: 0;
    right: -100vw;
    width:100vw;
    height: 100vh;
    background: radial-gradient(circle, rgb(61, 0, 51) 0%, rgba(171,0,255,1) 100%);

    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  
    box-sizing: border-box;
    z-index: 999999;
    transition: right 0.3s ease-in-out;
  }
  .navLink{

    color:white;
    font-size: 1rem;
font-family: "RubikR";
cursor: pointer;
transition: all ease 1s;
  }
  .navLink:hover{
    color:yellow;
transform: scale(1.1);

  }
  .row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width:30%;
  }
  .openLinks {
    right: 0;
  }
  
  .navLinks ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  .navLinks ul li {
    margin: 10px 0;
  }
  
  .navLinks ul li a {
    color: #fff;
    text-decoration: none;
   
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  

  .hamburger {
    cursor: pointer;
  }
  
  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: #ffffff;
    transition: all 0.3s ease-in-out;
  }
  
  .overlay {
  
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
  z-index: 999;
    opacity: 1;
    visibility: visible;
  }
  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
  }
  .image{
    height:70px;
    object-fit: contain;
  }
  @media only screen  and (max-width: 350px) {
    .image{
      height:55px;
      object-fit: contain;
    }
  }
  @media only screen and (min-width: 350px) and (max-width: 450px) {
    .image{
      height:65px;
      object-fit: contain;
    }
  }
  @media only screen  and (min-width: 450px) and (max-width: 650px) {

    .image{
      height:70px;
      object-fit: contain;
    }
    .navLink{

      color:white;
      font-size: 1.1rem;
  font-family: "RubikR";
  cursor: pointer;
  transition: all
   ease 1s;
    }
    .navLinks ul li {
      margin: 20px 0;
    }
    
  }
  @media only screen  and (min-width: 650px) and (max-width: 850px) {

    .image{
      height:70px;
      object-fit: contain;
    }
    .navLink{

      color:white;
      font-size: 1.2rem;
  font-family: "RubikR";
  cursor: pointer;
  transition: all
   ease 1s;
    }
    .navLinks ul li {
      margin: 25px 0;
    }
    
  }

  @media only screen  and (min-width: 850px) and (max-width: 1050px) {
    .image{
      height:75px;
      object-fit: contain;
    }
    .row{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width:45%;
    }
    
  }
  @media only screen  and (min-width: 1050px) and (max-width: 1250px) {

    .row{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width:40%;
    }
    
  }